import { Backend } from "~/utils/backend";
const $backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
import { checkStatus } from "/global/utils/helpers";
export default {
  URL: "courses/",

  /**
   * @return Promise
   * @param {object} params
   * @param opts
   */
  find(params = {}, opts = {}) {
    // todo maybe do some transformation. Not needed for now

    return $backend
      .get("teachers_get_courses", { params, ...opts })
      .then(checkStatus)
      .then(r => r.data.content.courses);
  },
  /**
   * @return Promise
   * @param course
   * @param headers
   */
  save(course, headers = {}) {
    return $backend
      .post("teachers_post_courses", course, headers)
      .then(checkStatus);
  },
  delete(model, opts = {}) {
    const data = {
      course_id: model.course_id,
      partner_code: model.partner_code
    };

    let config = { data, ...opts, shouldSendPartnerCode: false };
    return $backend.delete("teachers_delete_courses", config).then(checkStatus);
  }
};
