// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "#courseNameImage {\n  border: 2px solid #fff;\n  border-radius: 50%;\n  background: #ffffff;\n  color: #333;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n.course-stat {\n  font-family: Avenir, sans-serif;\n  border-radius: 10px;\n  height: 100%;\n  flex-grow: 1;\n}\n.line-clamp {\n  display: -webkit-box;\n  -webkit-line-clamp: 2;\n  -webkit-box-orient: vertical;\n  overflow: hidden;\n}\n.content-container-style {\n  padding: 0px;\n  height: 100%;\n}", ""]);
// Exports
exports.locals = {
	"primary": "#0099dc",
	"orange": "#FC6222",
	"decline": "#ff4848"
};
module.exports = exports;
