var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "ft-avenir lg:text-xs xl:text-sm",
      style: `border: .1rem solid #eee; border-radius: 8px; height: ${_vm.sizes.cardHeight}; width: 100%;`,
      attrs: {
        elevation: "0",
        to: `/teachers/${_vm.course.partner_code}/courses/${_vm.course.course_id}`,
        ripple: false,
      },
    },
    [
      _c("dialog-delete", {
        attrs: {
          deleting: _vm.deleting,
          item: _vm.course,
          "item-text": "Course",
        },
        on: {
          "confirm-remove": function ($event) {
            return _vm.removeCourse(true)
          },
        },
        model: {
          value: _vm.courseDeleteDialog,
          callback: function ($$v) {
            _vm.courseDeleteDialog = $$v
          },
          expression: "courseDeleteDialog",
        },
      }),
      _c("div", { staticClass: "d-flex", staticStyle: { height: "100%" } }, [
        _vm.screenSize > 800
          ? _c(
              "div",
              [
                _vm.course.image_url !== undefined
                  ? _c("v-img", {
                      staticStyle: { "border-radius": "8px 0 0 8px" },
                      attrs: {
                        src: _vm.courseImage,
                        height: _vm.sizes.imageHeight,
                        "max-width": _vm.sizes.imageWidth,
                      },
                    })
                  : _c(
                      "div",
                      {
                        style:
                          "background: #666666; min-width: " +
                          _vm.sizes.imageWidth +
                          "; height: 100%; border-top-left-radius: 10px; border-bottom-left-radius: 10px;" +
                          "display: flex; align-items: center; justify-content: center;",
                      },
                      [
                        _c(
                          "div",
                          {
                            style: `width: ${
                              _vm.$vuetify.breakpoint.lgAndDown
                                ? "84px"
                                : "120px"
                            };
          height: ${_vm.$vuetify.breakpoint.lgAndDown ? "84px" : "120px"};
          font-size: ${_vm.$vuetify.breakpoint.lgAndDown ? "48px" : "70px"}
          `,
                            attrs: { id: "courseNameImage" },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.getCourseImageFromTitle(
                                    _vm.course.course_title
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                      ]
                    ),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "flex-grow-1 px-xl-7 px-lg-4 px-sm-4 px-3",
            staticStyle: { width: "280px", height: "100%" },
          },
          [
            _c(
              "v-container",
              { staticClass: "content-container-style" },
              [
                _c(
                  "v-row",
                  {
                    staticClass: "mt-xl-6 mt-lg-5 mt-sm-4 mt-2",
                    attrs: { "no-gutters": "" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "ft-avenir xl:text-2xl lg:text-base md:text-base sm:text-base font-weight-bold d-inline-block text-truncate",
                        staticStyle: { width: "calc(100% - 30px)" },
                      },
                      [_vm._v(" " + _vm._s(_vm.course.course_title) + " ")]
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-menu",
                          {
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function ({ on: { click }, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._b(
                                        {
                                          staticClass: "clickable",
                                          staticStyle: {
                                            width: "30px",
                                            height: "20px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return click.apply(
                                                null,
                                                arguments
                                              )
                                            },
                                          },
                                        },
                                        "div",
                                        attrs,
                                        false
                                      ),
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            staticStyle: {
                                              height: "20px",
                                              "background-color": "transparent",
                                              color: "#202020",
                                            },
                                          },
                                          [_vm._v("mdi-dots-vertical")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "v-list",
                              { staticClass: "lg:text-xs xl:text-sm" },
                              [
                                _c(
                                  "v-list-item",
                                  { attrs: { disabled: _vm.disableActions } },
                                  [
                                    _c(
                                      "v-list-item-title",
                                      {
                                        staticClass:
                                          "clickable px-lg-3 px-xl-4",
                                        on: {
                                          click: function ($event) {
                                            return _vm.$router.push({
                                              name: "course-edit",
                                              params: {
                                                partner_code:
                                                  _vm.course.partner_code,
                                                courseId: _vm.course.course_id,
                                                course: _vm.course,
                                              },
                                            })
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            !_vm.$language
                                              ? "Edit Course"
                                              : "এডিট কোর্স"
                                          )
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-list-item",
                                  { attrs: { disabled: _vm.disableActions } },
                                  [
                                    !_vm.$language
                                      ? _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "clickable px-lg-3 px-xl-4",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeCourse(false)
                                              },
                                            },
                                          },
                                          [_vm._v("Delete Course")]
                                        )
                                      : _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "clickable px-lg-3 px-xl-4",
                                            on: {
                                              click: function ($event) {
                                                return _vm.removeCourse(false)
                                              },
                                            },
                                          },
                                          [_vm._v("ডিলিট কোর্স")]
                                        ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                ),
                _c(
                  "v-row",
                  {
                    staticClass: "mt-xl-8 mt-lg-5 mt-sm-2 mt-3",
                    attrs: { "no-gutters": "" },
                  },
                  [
                    _c(
                      "p",
                      {
                        staticClass: "line-clamp",
                        style: `color: #5b5c5e; -webkit-line-clamp: ${
                          _vm.sizes.lineClamp
                        }; height: ${_vm.sizes.lineClamp * 1.35}em`,
                      },
                      [
                        !_vm.$language
                          ? _c(
                              "span",
                              {
                                staticClass:
                                  "font-weight-bold md:text-sm sm:text-sm",
                                staticStyle: { color: "#202020" },
                              },
                              [_vm._v("Course Description: ")]
                            )
                          : _c(
                              "span",
                              {
                                staticClass:
                                  "font-weight-bold md:text-sm sm:text-sm",
                                staticStyle: { color: "#202020" },
                              },
                              [_vm._v("কোর্সের বিবরণ: ")]
                            ),
                        _c("span", { staticClass: "md:text-sm sm:text-sm" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.course.course_description.htmlToText()
                              ) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "v-row",
                  {
                    staticClass:
                      "mt-xl-5 mt-lg-2 mt-3 lg:text-xss xl:text-base",
                    attrs: { "no-gutters": "", justify: "space-between" },
                  },
                  [
                    _vm._t("detail-1", function () {
                      return [
                        !_vm.$language
                          ? _c(
                              "v-chip",
                              {
                                style: _vm.$vuetify.breakpoint.xs
                                  ? `font-size: 11px`
                                  : ``,
                                attrs: { color: "primary" },
                              },
                              [
                                _vm._v(
                                  "Active Students: " +
                                    _vm._s(_vm.course.num_students) +
                                    " "
                                ),
                              ]
                            )
                          : _c(
                              "v-chip",
                              {
                                style: _vm.$vuetify.breakpoint.xs
                                  ? `font-size: 11px`
                                  : ``,
                                attrs: { color: "primary" },
                              },
                              [
                                _vm._v(
                                  "এক্টিভ শিক্ষার্থী: " +
                                    _vm._s(_vm.course.num_students) +
                                    " "
                                ),
                              ]
                            ),
                      ]
                    }),
                    _vm._t("detail-2", function () {
                      return [
                        !_vm.$language
                          ? _c(
                              "v-chip",
                              {
                                style: _vm.$vuetify.breakpoint.xs
                                  ? `font-size: 11px`
                                  : ``,
                                attrs: { outlined: "", color: "primary" },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.course.num_batches) +
                                    " " +
                                    _vm._s(
                                      _vm.course.num_batches < 2
                                        ? "Batch"
                                        : "Batches"
                                    )
                                ),
                              ]
                            )
                          : _c(
                              "v-chip",
                              {
                                style: _vm.$vuetify.breakpoint.xs
                                  ? `font-size: 11px`
                                  : ``,
                                attrs: { outlined: "", color: "primary" },
                              },
                              [
                                _vm._v(
                                  "ব্যাচ সংখ্যা: " +
                                    _vm._s(_vm.course.num_batches) +
                                    " "
                                ),
                              ]
                            ),
                      ]
                    }),
                  ],
                  2
                ),
                _vm.fetching && !_vm.itemCount
                  ? _c(
                      "v-row",
                      {
                        attrs: {
                          justify: "center",
                          align: "center",
                          "no-gutters": "",
                        },
                      },
                      [
                        _c("v-progress-circular", {
                          attrs: { indeterminate: "", color: "primary" },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "v-row",
                      {
                        staticClass:
                          "mt-xl-5 mt-lg-4 mt-4 lg:text-xs xl:text-base",
                        style: { height: _vm.sizes.stat.height },
                        attrs: { "no-gutters": "" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "course-stat pt-1 pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 mr-sm-2 mr-1 text-center",
                            style: ` background-color: #cce2d1;`,
                          },
                          [
                            !_vm.$language
                              ? _c(
                                  "p",
                                  {
                                    style: this.$vuetify.breakpoint.xs
                                      ? `font-size: 14px`
                                      : ``,
                                  },
                                  [_vm._v(" Lecture ")]
                                )
                              : _c(
                                  "p",
                                  {
                                    style: this.$vuetify.breakpoint.xs
                                      ? `font-size: 14px`
                                      : ``,
                                  },
                                  [_vm._v(" লেকচার ")]
                                ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "ft-medium mt-xl-3 mt-lg-2 ft-avenir",
                                style: {
                                  fontSize: this.$vuetify.breakpoint.mdAndDown
                                    ? "14px"
                                    : this.$vuetify.breakpoint.lg
                                    ? "17px"
                                    : "24px",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.itemCount.num_lectures) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "course-stat pt-1 pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 mr-sm-2 mr-1 text-center",
                            style: ` background-color: #ffdfd2;`,
                          },
                          [
                            !_vm.$language
                              ? _c(
                                  "p",
                                  {
                                    style: this.$vuetify.breakpoint.xs
                                      ? `font-size: 14px`
                                      : ``,
                                  },
                                  [_vm._v(" Assignment ")]
                                )
                              : _c(
                                  "p",
                                  {
                                    style: this.$vuetify.breakpoint.xs
                                      ? `font-size: 14px`
                                      : ``,
                                  },
                                  [_vm._v(" এসাইনমেন্ট ")]
                                ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "ft-medium mt-xl-3 mt-lg-2 ft-avenir",
                                style: {
                                  fontSize: this.$vuetify.breakpoint.mdAndDown
                                    ? "14px"
                                    : this.$vuetify.breakpoint.lg
                                    ? "17px"
                                    : "24px",
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.itemCount.num_assignments) +
                                    " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "course-stat pt-1 pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 mr-sm-2 mr-1 text-center",
                            style: ` background-color: #cce7f5;`,
                          },
                          [
                            !_vm.$language
                              ? _c(
                                  "p",
                                  {
                                    style: this.$vuetify.breakpoint.xs
                                      ? `font-size: 14px`
                                      : ``,
                                  },
                                  [_vm._v(" Exam ")]
                                )
                              : _c(
                                  "p",
                                  {
                                    style: this.$vuetify.breakpoint.xs
                                      ? `font-size: 14px`
                                      : ``,
                                  },
                                  [_vm._v(" পরীক্ষা ")]
                                ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "ft-medium mt-xl-3 mt-lg-2 ft-avenir",
                                style: {
                                  fontSize: this.$vuetify.breakpoint.mdAndDown
                                    ? "14px"
                                    : this.$vuetify.breakpoint.lg
                                    ? "17px"
                                    : "24px",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.itemCount.num_exams) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "course-stat pt-1 pt-xl-3 pt-lg-2 text-center",
                            style: ` background-color: #faccff;`,
                          },
                          [
                            !_vm.$language
                              ? _c(
                                  "p",
                                  {
                                    style: this.$vuetify.breakpoint.xs
                                      ? `font-size: 14px`
                                      : ``,
                                  },
                                  [_vm._v(" Project ")]
                                )
                              : _c(
                                  "p",
                                  {
                                    style: this.$vuetify.breakpoint.xs
                                      ? `font-size: 14px`
                                      : ``,
                                  },
                                  [_vm._v(" প্রজেক্ট ")]
                                ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "ft-medium mt-xl-3 mt-lg-2 ft-avenir",
                                style: {
                                  fontSize: this.$vuetify.breakpoint.mdAndDown
                                    ? "14px"
                                    : this.$vuetify.breakpoint.lg
                                    ? "17px"
                                    : "24px",
                                },
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.itemCount.num_projects) + " "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }