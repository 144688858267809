import { Backend } from "~/utils/backend";
const backend = new Backend(process.env.VUE_APP_BACKEND_EDU_TEACHERS_URL);
import { checkStatus, Logger } from "/global/utils/helpers";

export async function getOwnStats($store) {
  const { data } = await backend
    .get("teachers_get_own_stats")
    .then(checkStatus);
  $store.commit("courses/setMyStats", data.content.stats);
  return data.content.stats;
}

export async function getOrgStats($store) {
  const { data } = await backend
    .get("teachers_get_org_stats")
    .then(checkStatus);
  $store.commit("courses/setOrgStats", data.content.stats);
  return data.content.stats;
}

export async function getOwnCourses($store) {
  const { data } = await backend
    .get("teachers_get_own_courses")
    .then(checkStatus);
  $store.commit("courses/setMyCourses", data.content.courses);
  return data.content.courses;
}

export async function getOrgCourses($store) {
  const { data } = await backend
    .get("teachers_get_organization_courses")
    .then(checkStatus);
  $store.commit("courses/setOrgCourses", data.content.courses);
  return data.content.courses;
}
export async function getCourseItemsCount($store, course_id, partner_code) {
  const { data } = await backend
    .get("teachers_get_course_items_count", {
      params: { course_id, partner_code },
      shouldSendPartnerCode: false
    })
    .then(checkStatus);
  $store.commit("courses/setItemsCount", {
    courseId: course_id,
    itemsCount: data.content.item_count
  });
  return data.content.item_count;
}
