<template>
  <v-card
    :style="`border: .1rem solid #eee; border-radius: 8px; height: ${sizes.cardHeight}; width: 100%;`"
    class="ft-avenir lg:text-xs xl:text-sm"
    elevation="0"
    :to="`/teachers/${course.partner_code}/courses/${course.course_id}`"
    :ripple="false"
  >
    <dialog-delete
      v-model="courseDeleteDialog"
      :deleting="deleting"
      :item="course"
      item-text="Course"
      @confirm-remove="removeCourse(true)"
    ></dialog-delete>
    <div class="d-flex" style="height: 100%">
      <div v-if="screenSize > 800">
        <v-img
          v-if="course.image_url !== undefined"
          :src="courseImage"
          :height="sizes.imageHeight"
          :max-width="sizes.imageWidth"
          style="border-radius: 8px 0 0 8px"
        ></v-img>
        <div
          v-else
          :style="
            'background: #666666; min-width: ' +
            sizes.imageWidth +
            '; height: 100%; border-top-left-radius: 10px; border-bottom-left-radius: 10px;' +
            'display: flex; align-items: center; justify-content: center;'
          "
        >
          <div
            id="courseNameImage"
            :style="`width: ${$vuetify.breakpoint.lgAndDown ? '84px' : '120px'};
            height: ${$vuetify.breakpoint.lgAndDown ? '84px' : '120px'};
            font-size: ${$vuetify.breakpoint.lgAndDown ? '48px' : '70px'}
            `"
          >
            {{ getCourseImageFromTitle(course.course_title) }}
          </div>
        </div>
      </div>
      <div
        class="flex-grow-1 px-xl-7 px-lg-4 px-sm-4 px-3"
        style="width: 280px; height: 100%"
      >
        <v-container class="content-container-style">
          <v-row no-gutters class="mt-xl-6 mt-lg-5 mt-sm-4 mt-2">
            <div
              style="width: calc(100% - 30px)"
              class="ft-avenir xl:text-2xl lg:text-base md:text-base sm:text-base font-weight-bold d-inline-block text-truncate"
            >
              {{ course.course_title }}
            </div>
            <div>
              <v-menu>
                <template v-slot:activator="{ on: { click }, attrs }">
                  <div
                    class="clickable"
                    v-bind="attrs"
                    style="width: 30px; height: 20px"
                    @click.stop.prevent="click"
                  >
                    <v-icon
                      style="
                        height: 20px;
                        background-color: transparent;
                        color: #202020;
                      "
                      >mdi-dots-vertical</v-icon
                    >
                  </div>
                </template>
                <v-list class="lg:text-xs xl:text-sm">
                  <v-list-item :disabled="disableActions">
                    <!-- <v-list-item-title
                      class="clickable px-lg-3 px-xl-4"
                      @click="$router.push('course-edit')"
                      >Edit Course</v-list-item-title
                    > -->
                    <v-list-item-title
                      class="clickable px-lg-3 px-xl-4"
                      @click="
                        $router.push({
                          name: 'course-edit',
                          params: {
                            partner_code: course.partner_code,
                            courseId: course.course_id,
                            course: course,
                          },
                        })
                      "
                      >{{
                        !$language ? "Edit Course" : "এডিট কোর্স"
                      }}</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item :disabled="disableActions">
                    <v-list-item-title
                      v-if="!$language"
                      class="clickable px-lg-3 px-xl-4"
                      @click="removeCourse(false)"
                      >Delete Course</v-list-item-title
                    >
                    <v-list-item-title
                      v-else
                      class="clickable px-lg-3 px-xl-4"
                      @click="removeCourse(false)"
                      >ডিলিট কোর্স</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-row>
          <v-row no-gutters class="mt-xl-8 mt-lg-5 mt-sm-2 mt-3">
            <p
              class="line-clamp"
              :style="`color: #5b5c5e; -webkit-line-clamp: ${
                sizes.lineClamp
              }; height: ${sizes.lineClamp * 1.35}em`"
            >
              <!--              {{ course.course_description }}-->
              <span
                v-if="!$language"
                class="font-weight-bold md:text-sm sm:text-sm"
                style="color: #202020"
                >Course Description:
              </span>
              <span
                v-else
                class="font-weight-bold md:text-sm sm:text-sm"
                style="color: #202020"
                >কোর্সের বিবরণ:
              </span>
              <span class="md:text-sm sm:text-sm">
                {{ course.course_description.htmlToText() }}
              </span>
            </p>
          </v-row>
          <v-row
            no-gutters
            justify="space-between"
            class="mt-xl-5 mt-lg-2 mt-3 lg:text-xss xl:text-base"
          >
            <slot name="detail-1">
              <v-chip
                v-if="!$language"
                color="primary"
                :style="$vuetify.breakpoint.xs ? `font-size: 11px` : ``"
                >Active Students: {{ course.num_students }}
              </v-chip>
              <v-chip
                v-else
                color="primary"
                :style="$vuetify.breakpoint.xs ? `font-size: 11px` : ``"
                >এক্টিভ শিক্ষার্থী: {{ course.num_students }}
              </v-chip>
            </slot>
            <slot name="detail-2">
              <v-chip
                v-if="!$language"
                outlined
                color="primary"
                :style="$vuetify.breakpoint.xs ? `font-size: 11px` : ``"
                >{{ course.num_batches }}
                {{ (course.num_batches &lt; 2) ? "Batch" : "Batches" }}</v-chip
              >
              <v-chip
                v-else
                outlined
                color="primary"
                :style="$vuetify.breakpoint.xs ? `font-size: 11px` : ``"
                >ব্যাচ সংখ্যা: {{ course.num_batches }}
              </v-chip>
            </slot>
          </v-row>
          <!--            <v-row class="grid grid-cols-4" style="grid-column-gap: 10px">-->
          <v-row
            v-if="fetching && !itemCount"
            justify="center"
            align="center"
            no-gutters
          >
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-row>
          <v-row
            v-else
            no-gutters
            class="mt-xl-5 mt-lg-4 mt-4 lg:text-xs xl:text-base"
            :style="{ height: sizes.stat.height }"
          >
            <div
              class="course-stat pt-1 pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 mr-sm-2 mr-1 text-center"
              :style="` background-color: #cce2d1;`"
            >
              <p
                v-if="!$language"
                :style="this.$vuetify.breakpoint.xs ? `font-size: 14px` : ``"
              >
                Lecture
              </p>
              <p
                v-else
                :style="this.$vuetify.breakpoint.xs ? `font-size: 14px` : ``"
              >
                লেকচার
              </p>
              <p
                class="ft-medium mt-xl-3 mt-lg-2 ft-avenir"
                :style="{
                  fontSize: this.$vuetify.breakpoint.mdAndDown
                    ? '14px'
                    : this.$vuetify.breakpoint.lg
                    ? '17px'
                    : '24px',
                }"
              >
                {{ itemCount.num_lectures }}
              </p>
            </div>
            <div
              class="course-stat pt-1 pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 mr-sm-2 mr-1 text-center"
              :style="` background-color: #ffdfd2;`"
            >
              <p
                v-if="!$language"
                :style="this.$vuetify.breakpoint.xs ? `font-size: 14px` : ``"
              >
                Assignment
              </p>
              <p
                v-else
                :style="this.$vuetify.breakpoint.xs ? `font-size: 14px` : ``"
              >
                এসাইনমেন্ট
              </p>
              <p
                class="ft-medium mt-xl-3 mt-lg-2 ft-avenir"
                :style="{
                  fontSize: this.$vuetify.breakpoint.mdAndDown
                    ? '14px'
                    : this.$vuetify.breakpoint.lg
                    ? '17px'
                    : '24px',
                }"
              >
                {{ itemCount.num_assignments }}
              </p>
            </div>
            <div
              class="course-stat pt-1 pt-xl-3 pt-lg-2 mr-xl-3 mr-lg-2 mr-sm-2 mr-1 text-center"
              :style="` background-color: #cce7f5;`"
            >
              <p
                v-if="!$language"
                :style="this.$vuetify.breakpoint.xs ? `font-size: 14px` : ``"
              >
                Exam
              </p>
              <p
                v-else
                :style="this.$vuetify.breakpoint.xs ? `font-size: 14px` : ``"
              >
                পরীক্ষা
              </p>
              <p
                class="ft-medium mt-xl-3 mt-lg-2 ft-avenir"
                :style="{
                  fontSize: this.$vuetify.breakpoint.mdAndDown
                    ? '14px'
                    : this.$vuetify.breakpoint.lg
                    ? '17px'
                    : '24px',
                }"
              >
                {{ itemCount.num_exams }}
              </p>
            </div>
            <div
              class="course-stat pt-1 pt-xl-3 pt-lg-2 text-center"
              :style="` background-color: #faccff;`"
            >
              <p
                v-if="!$language"
                :style="this.$vuetify.breakpoint.xs ? `font-size: 14px` : ``"
              >
                Project
              </p>
              <p
                v-else
                :style="this.$vuetify.breakpoint.xs ? `font-size: 14px` : ``"
              >
                প্রজেক্ট
              </p>
              <p
                class="ft-medium mt-xl-3 mt-lg-2 ft-avenir"
                :style="{
                  fontSize: this.$vuetify.breakpoint.mdAndDown
                    ? '14px'
                    : this.$vuetify.breakpoint.lg
                    ? '17px'
                    : '24px',
                }"
              >
                {{ itemCount.num_projects }}
              </p>
            </div>
          </v-row>
        </v-container>
      </div>
    </div>
  </v-card>
</template>

<script>
import courseService from "#ecf/course/services/CourseService";
import { getCourseItemsCount } from "#ecf/courses/actions";
import DialogDelete from "@ecf/global/components/DialogDelete";
export default {
  name: "CardCourse",
  components: { DialogDelete },
  props: {
    course: {
      type: Object,
      required: true,
    },
    small: {
      type: Boolean,
      required: false,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fetching: true,
      deleting: false,
      courseDeleteDialog: false,
    };
  },
  computed: {
    screenSize() {
      return this.$vuetify.breakpoint.width;
    },
    courseImage() {
      return "image_url" in this.course
        ? this.course.image_url
        : require("../assets/img.png");
    },
    itemCount() {
      const count = this.$store.state.courses.itemsCount[this.course.course_id];
      if (!count) return {};
      return count;
    },
    sizes() {
      if (this.small) {
        return {
          cardHeight: this.$vuetify.breakpoint.lgAndDown ? "205px" : "210px",
          cardWidth: this.$vuetify.breakpoint.lgAndDown ? "358px" : "475px",
          bodyFontSize: ".75rem",
          imageWidth: "30%",
          titleFontSize: "1.125rem",
          statFont: this.$vuetify.breakpoint.lgAndDown ? "12px" : "14px",
          lineClamp: 2,
        };
      } else {
        return {
          cardHeight: this.$vuetify.breakpoint.lgAndDown ? "224px" : "320px",
          cardWidth: this.$vuetify.breakpoint.lgAndDown ? "493px" : "710px",
          bodyFontSize: ".875rem",
          imageWidth: this.$vuetify.breakpoint.lgAndDown ? "160px" : "230px",
          imageHeight: this.$vuetify.breakpoint.lgAndDown ? "220px" : "316px",
          titleFontSize: "1.5rem",
          statFont: this.$vuetify.breakpoint.lgAndDown ? "14px" : "16px",
          lineClamp: 2,
          stat: {
            height: this.$vuetify.breakpoint.lgAndDown ? "60px" : "92px",
          },
        };
      }
    },
  },
  async created() {
    try {
      this.fetching = true;
      await getCourseItemsCount(
        this.$store,
        this.course.course_id,
        this.course.partner_code
      );
    } catch (e) {
      this.$root.$emit("alert", [undefined, e.message || e]);
    } finally {
      this.fetching = false;
    }
  },
  methods: {
    editCourse() {
      this.$root.$emit("alert", [
        undefined,
        "this feature is not implementd yet",
      ]);
    },
    getCourseImageFromTitle(courseTitle) {
      let words = courseTitle.split(" ");
      return words
        .map((w) => w[0])
        .join("")
        .toUpperCase()
        .slice(0, 2); // taking only two
    },
    async removeCourse(isConfirmed) {
      if (!isConfirmed) {
        this.courseDeleteDialog = true;
        return;
      }
      try {
        this.deleting = true;
        await courseService.delete(this.course);
        this.$emit("deleted");
        this.courseDeleteDialog = false;
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.deleting = false;
      }
    },
  },
};
</script>

<style lang="scss">
#courseNameImage {
  border: 2px solid #fff;
  border-radius: 50%;
  background: #ffffff;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}
.course-stat {
  //font-size: 1em;
  font-family: Avenir, sans-serif;
  border-radius: 10px;
  height: 100%;
  flex-grow: 1;
  //min-width: 65px;
}
.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.content-container-style {
  padding: 0px;
  height: 100%;
  @media screen and (min-width: 1601px) {
  }

  @media screen and (min-width: 801px) and (max-width: 1600px) {
  }

  @media screen and (min-width: 600px) and (max-width: 800px) {
  }

  @media screen and (max-width: 599px) {
  }
}
</style>
